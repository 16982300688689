import DateInput from './date-input'

// TODO: provide as data-availability attribute on form element
//       Requires CMTForm to allow setting custom attributes on the form element
let defaultHours = [
  [[0, 23.75]],
  [[0, 23.75]],
  [[0, 23.75]],
  [[0, 23.75]],
  [[0, 23.75]],
  [[0, 23.75]],
  [[0, 23.75]]
]

export default class DatePickerForm {
  constructor (form) {
    this.form = form

    this.dateInput = new DateInput(
      form.getInputByName('year'),
      form.getInputByName('month'),
      form.getInputByName('day'),
      form.getInputByName('time')
    )

    let hoursInAdvance = 0
    let dateNowWithoutTime = new Date()
    dateNowWithoutTime.setUTCHours(0, 0, 0, 0)
    let minTime = Date.now() + hoursInAdvance * 1000 * 60 * 60
    let maxTime = +dateNowWithoutTime + 365 * 1000 * 60 * 60 * 24
    let hours = defaultHours
    if (form.element.id.indexOf('cmt-form-bokningsfrfrganjulbord') === 0) {
      // 23rd november, and after that 3 days in advance
      minTime = Math.max(
        +new Date('2023-11-29'),
        Date.now() + 72 * 1000 * 60 * 60
      )
      maxTime = +new Date('2023-12-22')
      hours = [
        [[0, 0]],
        [[0, 0]],
        [[0, 23.75]],
        [[0, 23.75]],
        [[0, 23.75]],
        [[0, 23.75]],
        [[0, 23.75]]
      ]
    }

    this.dateInput.initializeAvailability(
      hours,
      minTime,
      maxTime
    )
    this.dateInput.setFirstAvailable()
    this.dateInput.handleChange()
  }
}
